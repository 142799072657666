import React from "react"
import {useIsMobile} from "../utils";
import Hero from "../components/hero";
import Story from "../components/story";
import Contact from "../components/contact";
import { SEO } from '../components/seo';


const WholeSale = ({
                   data,
                   pageContext: {nextPagePath, previousPagePath},
               }) => {
    const {isMobile} = useIsMobile();
    return (
      <div>
						<Hero 
							images={[
								'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/wholesale-hero-1.jpg',
								'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/wholesale-hero-2.jpg',
								'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/wholesale-hero-3.jpg',
							]}
						/>
            <div
                style={{
                    padding: '4rem 4rem 0 4rem',
                    maxWidth: 732,
                    textAlign: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0px auto',
                }}
                className="title3"
            >
                WHOLESALE
                <br/>
                <div className="title1">
                    商業批發
                </div>
                <div className="body1" style={{textAlign: 'justify', padding: isMobile ? '0 16px' : '64px 0'}}>
                  好的咖啡應該要存在生活的每一個角落，不管是辦公室、咖啡廳，或著是飯店與民宿，任何的商業空間都可以成為我們的合作客戶。合作咖啡致力於提供新鮮的咖啡豆給喜歡我們的客戶，品質的把關一直是我們的堅持。咖啡是季節性的產物，我們每一個月都會更新咖啡豆品項，讓我們的夥伴可以即時品嚐當季新鮮的咖啡豆。
                </div>
            </div>
            <br/>
            <div style={{ maxWidth: 1100, margin: '0px auto'}}>
                <Story
                  imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/wholesale-pic2.jpg"
                    header="我們對於商業夥伴的五大堅持"
                    content={<p>
                    <div className="body2">挑選當季新鮮咖啡生豆</div>
                    我們在挑選生豆時會選擇國內外信任的生豆商做採購，並選擇獨特且適合我們的咖啡豆作烘焙，也會針對當季量產的咖啡豆進行採購。
                      <br />
                      <br />
                    <div className="body2">
                    新鮮烘焙咖啡豆
                    </div>
                    每週新鮮烘焙，並計算好養豆時間，再寄送到客人手中，以確保咖啡風味的穩定。我們每一包咖啡豆都會在豆袋外標示咖啡豆的名稱和烘焙的日期，並附上完整的咖啡豆資訊交到每一位客人手上。我們對合作咖啡的每一項產品皆有投保食品安全責任險。
                      <br />
                      <br />
                    義式配方養豆時間為 14 天，手沖咖啡養豆時間為 7 天。
                    </p>}
                />
                <div style={{ height: 64}} />
                <Story
                  imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/wholesale-pic3.jpg"
                    header=""
                    direction="left"
                    content={<p>
                    <div className="body2">
                      協助商業夥伴挑選適合的咖啡豆
                    </div>
                      咖啡豆的風味會因為使用的設備、水質以及沖煮方式而有所不同，我們會盡力協助，親自到現場與客戶一起做測試，找出客戶喜歡的風味。

                      <br />
                      <br />
                    <div className="body2">
                      提供設備建議以及沖煮上的協助及調整
                    </div>
                      不同的設備以及水質在沖煮上一定會有差異，我們可以提供咖啡設備以及咖啡機濾水設備的規劃，幫助客戶選取符合需求的設備。
                      <br />
                      <br />
                    <div className="body2">
                      後續追蹤以及問題改善
                    </div>
                      我們會定期協助合作客戶咖啡風味校正，以確保咖啡風味，穩定供應。合作客戶在咖啡豆風味上有任何問題，都可以與我們反應，我們會及時為客戶做解答。
                      <br />
                      <br />
                      如還需要更多關於商業批發的資訊，歡迎聯絡我們，我們會在三天內與您聯繫。
                    </p>}
                />
            </div>
            <div style={{ height: 64}} />
            <Contact />
        </div>
    )
}

export default WholeSale;
export const Head = () => (
  <SEO
    title="商業批發"
    description="好的咖啡應該要存在生活的每一個角落，不管是辦公室、咖啡廳，或著是飯店與民宿，任何的商業空間都可以成為我們的合作客戶。合作咖啡致力於提供新鮮的咖啡豆給喜歡我們的客戶，品質的把關一直是我們的堅持。咖啡是季節性的產物，我們每一個月都會更新咖啡豆品項，讓我們的夥伴可以即時品嚐當季新鮮的咖啡豆。"
    image="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/wholesale-hero-3.jpg"
  />
)
